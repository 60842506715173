<template>
  <div>
    <Full-calendar
      ref="calendar-api"
      class="calendar"
      :options="calendarOptions"
      :class="{ 'calendar-all': calendar }"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  data() {
    return {
      calendar: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        interactionPlugin: "dayGridMonth",
        locale: "ru-RU",
        businessHours: [],
        headerToolbar: {
          start: "title",
          center: "",
          end: "prev today next myCustomButton",
        },
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
        },
        buttonText: {
          today: "Сегодня",
        },
        firstDay: 1,
        events: [],
        datesSet: this.changeMonth,
        customButtons: {
          myCustomButton: {
            text: "",
            click: (e) => {
              const calendar = document.querySelector(".calendar");
              calendar.classList.toggle("calendar-all");
              const calendarApi = this.$refs["calendar-api"].getApi();
              calendarApi.next();
              calendarApi.prev();
              this.changeMonth(e);
            },
          },
        },
      },
      month: null,
    };
  },
  computed: {
    ...mapState(["schedule"]),
  },
  components: {
    FullCalendar,
  },
  mounted() {},
  async created() {
    this.$emit("spinner");
    // if(!this.schedule)
    //   ;
  },
  methods: {
    changeMonth(e) {
      this.getEvents(+e.endStr?.slice(5, 7));
    },
    async getEvents(month) {
      if (month === this.month && month) {
        return;
      }
      this.month = month;
      if (!this.schedule)
        await this.$store.dispatch("getSchedule", {month: month - 1})
          .then((response) => {
            if (response?.data?.success === true) this.calendarOptions.events.length = 0;
              this.makeEvents(this.schedule);
          });
      else this.makeEvents(this.schedule);
    },
    makeEvents(x) {
      x?.forEach((events) => {
        events?.date.forEach((event) => {
          let dateStr = event.replace(" ", "T");
          let date = new Date(dateStr);
          this.calendarOptions.events.push({
            id: events.id,
            start: date,
            title: events.region?.title,
            display: "list-item",
            color: "#1265ff",
          });
        });
      });
    }
  },
};
</script>

<style scoped>
.calendar {
  height: 350px;
  background: #ffffff;
  border-radius: 20px;
  padding: 12px;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #707070;
}

.calendar-all {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 18px;
  height: 100%;
  z-index: 5;
}
</style>
